/* eslint-disable @typescript-eslint/no-shadow */
import { Typography } from '@aph/ui/components/typography/typography';
import type { EditorialCardItem } from './editorial-card';
import { EditorialCard, EditorialCardSkeleton } from './editorial-card';

type EditorialListingProps = {
  title?: string;
  items?: Array<EditorialCardItem & { id?: string }> | undefined;
  children?: React.ReactNode;
  onItemClick?: (item: EditorialCardItem, index: number) => void;
  onItemInView?: (item: EditorialCardItem, index: number) => void;
};

export const EditorialListing = ({
  title,
  items,
  children,
  onItemClick,
  onItemInView,
}: EditorialListingProps) => (
  <div className="flex flex-col gap-y-2">
    {title && (
      <Typography typography="headingMedium" asChild>
        <h2>{title}</h2>
      </Typography>
    )}
    <div className="grid w-full grid-cols-2 gap-x-1 gap-y-2 sm:grid-cols-4 sm:gap-x-2">
      {items
        ? items?.map((item, index) => (
            <EditorialCard
              key={item.id ?? item.href}
              item={item}
              onClick={(item) => onItemClick?.(item, index + 1)}
              onInView={(item) => onItemInView?.(item, index + 1)}
            />
          ))
        : children}
    </div>
  </div>
);

export const EditorialListingSkeleton = ({ items, title }: { items: number; title: string }) => (
  <EditorialListing title={title}>
    {[...Array(items).keys()].map((i) => (
      <EditorialCardSkeleton key={i} />
    ))}
  </EditorialListing>
);
